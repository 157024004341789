<template>
    <div class="bg-blue py-10 relative overflow-hidden">
        <div class="page">
            <p class="title text-center">媒體通路</p>
            <p class="text-white text-center mb-2">DIGITAL PERFORMANCE</p>
            <div class="max-w-xl lg:max-w-2xl m-auto mt-10 mb-10">
                <div class="relative">
                    <img
                        id="rabbit2"
                        class="absolute rabbit r2 hidden md:block animate__slow"
                        src="/images/white_rabbit2.png"
                    />
                </div>
                <div id="circles">
                    <div class="px-10 py-2">
                        <div class="flex flex-wrap justify-center">
                            <div class="flex-half md:flex-quater">
                                <div class="m-media">
                                    <router-link to="/services#media">
                                        <img
                                            class="w-full p-3 lg:p-4"
                                            src="/images/m_01.png"
                                        />
                                    </router-link>
                                </div>
                            </div>
                            <div class="flex-half md:flex-quater">
                                <div class="m-media">
                                    <router-link to="/services#media">
                                        <img
                                            class="w-full p-3 lg:p-4"
                                            src="/images/m_02.png"
                                        />
                                    </router-link>
                                </div>
                            </div>
                            <div class="flex-half md:flex-quater">
                                <div class="m-media">
                                    <router-link to="/services#media">
                                        <img
                                            class="w-full p-3 lg:p-4"
                                            src="/images/m_03.png"
                                        />
                                    </router-link>
                                </div>
                            </div>

                            <div class="flex-half md:flex-quater">
                                <div class="m-media">
                                    <router-link to="/services#media">
                                        <img
                                            class="w-full p-3 lg:p-4"
                                            src="/images/m_04.png"
                                        />
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-wrap justify-center">
                            <div class="flex-half md:flex-quater">
                                <div class="m-media">
                                    <router-link to="/services#media">
                                        <img
                                            class="w-full p-3 lg:p-4"
                                            src="/images/m_05.png"
                                        />
                                    </router-link>
                                </div>
                            </div>
                            <div class="flex-half md:flex-quater">
                                <div class="m-media">
                                    <router-link to="/services#media">
                                        <img
                                            class="w-full p-3 lg:p-4"
                                            src="/images/m_06.png"
                                        />
                                    </router-link>
                                </div>
                            </div>
                            <div class="flex-half md:flex-quater">
                                <div class="m-media">
                                    <router-link to="/services#media">
                                        <img
                                            class="w-full p-3 lg:p-4"
                                            src="/images/m_07.png"
                                        />
                                    </router-link>
                                </div>
                            </div>
                            <div class="flex-half md:flex-quater">
                                <div class="m-media">
                                    <router-link to="/services#media">
                                        <img
                                            class="w-full p-3 lg:p-4"
                                            src="/images/m_08.png"
                                        />
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="relative">
                <img
                    id="rabbit1"
                    class="absolute rabbit r1 animate__fast"
                    src="/images/white_rabbit.png"
                />
            </div>
        </div>
    </div>
</template>
<script>
import animate from './../../animate';
export default {
    mounted() {
        new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    animate('#circles', 'fadeIn');
                }
            });
        }).observe(document.getElementById('circles'));

        new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    animate('#rabbit1', 'fadeInUp');
                }
            });
        }).observe(document.getElementById('rabbit1'));

        new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    animate('#rabbit2', 'fadeIn');
                }
            });
        }).observe(document.getElementById('rabbit2'));
    },
};
</script>
<style lang="scss" scoped>
.page {
    background: url('/images/media-bg.jpg') no-repeat center center;
    background-size: cover;
}

#circles {
    animation-delay: 0.8s;
}

.rabbit {
    width: 18vw;

    @media (max-width: 767px) {
        width: 20vh;
    }

    &.r1 {
        bottom: -5vw;
        @media (max-width: 767px) {
            bottom: -10vh;
        }
    }

    &.r2 {
        top: -5vw;
        right: -15vw;
    }
}

.m-media {
    @apply bg-white rounded-full m-4;
}
</style>
