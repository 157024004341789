<template>
    <div class="w-screen">
        <div class="landing-section w-screen flex items-center">
            <div class="meta-container text-center flex-1">
                <div id="logo-title" class="inline-block mb-4 animate__slow">
                    <p
                        class="
                            px-6
                            lg:px-8
                            tracking-widest
                            text-3xl text-white
                            lg:text-5xl
                            mtitle
                        "
                    >
                        優兔數位有限公司
                    </p>
                    <p
                        class="
                            mt-3
                            lg:mt-6
                            px-6
                            lg:px-8
                            text-2xl
                            lg:text-4xl
                            tracking-wider
                            text-white
                            mtitle
                        "
                    >
                        JUBBIT DIGITAL CO.LTD
                    </p>
                    <div class="blue-line bg-blue mt-4 lg:mt-8"></div>
                </div>
            </div>
        </div>
        <section class="wb-info bg-jbgray py-10 lg:py-20">
            <info></info>
        </section>
        <section class="bg-black text-white triangle">
            <service></service>
            <div class="triangle"></div>
        </section>

        <section class="mt-20">
            <performance></performance>
        </section>

        <section class="mb-8">
            <media></media>
        </section>

        <section class="">
            <partners></partners>
        </section>

        <section id="contact">
            <contact></contact>
        </section>
    </div>
</template>

<script>
import Performance from '../components/LandingPage/Performance.vue';
import Service from '../components/LandingPage/Service.vue';
import Info from '../components/LandingPage/Info.vue';
import Media from '../components/LandingPage/Media.vue';
import Partners from '../components/LandingPage/Partners.vue';
import Contact from '../components/LandingPage/Contact.vue';
import animate from './../animate';

export default {
    name: 'Home',
    components: {
        Performance,
        Service,
        Info,
        Media,
        Partners,
        Contact,
    },
    mounted() {
        if (window.IntersectionObserver) {
            new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        animate('#logo-title', 'fadeIn');
                    }
                });
            }).observe(document.getElementById('logo-title'));
        }
    },
};
</script>

<style lang="scss" scoped>
#logo-title {
    animation-delay: 0.5s;
}

.landing-section {
    height: calc(100vh - 56px);
    overflow-y: hidden;
    background: url('/images/landing.jpg') no-repeat center center;
    background-size: cover;
    position: relative;

    @media (max-width: 768px) {
        height: calc(100vh - 39px);
        background: url('/images/landing_m.jpg') no-repeat center bottom;
        background-size: cover;
    }

    .meta-container {
        .mtitle {
            text-align-last: justify;
        }

        .blue-line {
            width: 100%;
            height: 3px;
        }
    }
}

.triangle {
    &::after {
        content: ' ';
        position: absolute;
        left: calc(50% - 50px);
        border-top: 50px solid #373737;
        border-right: 50px solid transparent;
        border-left: 50px solid transparent;
        border-bottom: none;
    }
}

.main-box-container {
    margin-bottom: 1rem;
}
</style>
