import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
        path: '/services',
        name: 'Service',
        component: () =>
            import(/* webpackChunkName: "service" */ '../views/Service.vue'),
    },
    {
        path: '/service-partners',
        name: 'ServicePartner',
        component: () =>
            import(/* webpackChunkName: "partner" */ '../views/Partner.vue'),
    },
    {
        path: '/:catchAll(.*)',
        component: () =>
            import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return { el: to.hash };
        }
        return { top: 0 };
    },
});

router.beforeEach(() => {
    store.commit('CLOSE_MENU');
    store.commit('CLOSE_MENU_SERVICES');
});

export default router;
