const animateCSSGroup = (element, animation, prefix = 'animate__') =>
    // We create a Promise and return it
    new Promise((resolve) => {
        const animationName = `${prefix}${animation}`;
        const group = document.querySelectorAll(element);

        group.forEach((node) => {
            node.classList.add(`${prefix}animated`, animationName);
            node.style.setProperty('--animate-duration', `${1}s`);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, {
                once: true,
            });
        });
    });
export default animateCSSGroup;
