<template>
    <div class="container m-auto lg:px-16">
        <div class="dot p-8 lg:p-28">
            <div
                id="content"
                class="bg-jbgray px-2 py-4 xl:px-24 lg:py-6 animate__slower"
            >
                <p class="text-blue text-xl lg:text-3xl font-bold">
                    「專業、靈活、效率」是優兔數位獨特風格
                </p>
                <p class="mt-8 lg:leading-10 lg:text-xl">
                    主要服務項目為<span class="text-blue font-bold"
                        >「廣告投放」</span
                    >、<span class="text-blue font-bold">「策略規劃」</span
                    >與<span class="text-blue font-bold">「數據分析」</span>
                </p>
                <p class="mt-3 lg:mt-0 lg:leading-10 lg:text-xl">
                    AI 計算過去的數據，優兔帶領客戶擁有跳躍式的未來
                </p>
                <p class="mt-3 lg:mt-0 lg:leading-10 lg:text-xl">
                    針對客戶的 "品牌特性" 和
                    "產業趨勢"，提供多元的媒體規劃與內容服務
                </p>
                <p class="mt-3 lg:mt-0 lg:leading-10 lg:text-xl">
                    讓你感受到有溫度的合作夥伴，同時創造最大化的廣告效益！
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import animate from './../../animate';
export default {
    mounted() {
        new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    animate('#content', 'fadeIn');
                }
            });
        }).observe(document.getElementById('content'));
    },
};
</script>
<style lang="scss" scoped>
.dot {
    background: url('/images/dot.png') repeat;
    background-size: 120px;
    @media (max-width: 767px) {
        background-size: 60px;
    }
}

.container {
    max-width: 1280px;
    @media (max-width: 767px) {
        max-width: 94%;
    }
}
</style>
