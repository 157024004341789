<template>
    <div class="bg-black w-screen text-center text-white p-4">
        優兔數位有限公司 JUBBIT DIGITAL CO. LTD ©Copyright 2022
    </div>
</template>
<script>
export default {
    data() {
        return {
            showMenu: false,
        };
    },
    methods: {
        onToggleMenu() {
            this.showMenu = !this.showMenu;
        },
    },
};
</script>
