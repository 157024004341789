import { createStore } from 'vuex';

export default createStore({
    state: {
        isOpenMenu: false,
        isOpenServices: false,
    },
    getters: {
        isOpenMenu: (state) => {
            return state.isOpenMenu;
        },
        isOpenMenuService: (state) => {
            return state.isOpenServices;
        },
    },
    mutations: {
        ON_TOGGLE_MENU(state) {
            state.isOpenMenu = !state.isOpenMenu;
        },
        CLOSE_MENU(state) {
            state.isOpenMenu = false;
        },
        ON_TOGGLE_MENU_SERVICES(state) {
            state.isOpenServices = !state.isOpenServices;
        },
        CLOSE_MENU_SERVICES(state) {
            state.isOpenServices = false;
        },
    },
    actions: {},
});
