<template>
    <nav
        class="
            top-0
            sticky
            w-full
            flex flex-wrap
            items-center
            justify-between
            py-2
            md:px-4
            bg-blue
            shadow-lg
        "
    >
        <div
            class="
                container
                mx-auto
                flex flex-wrap
                items-center
                justify-between
            "
        >
            <div class="items-center justify-between w-auto">
                <div
                    class="inline-block relative w-5 h-4 mx-4 md:hidden mr-6"
                    :class="{ active: showMenu, absolute: showMenu }"
                    v-on:click="onToggleMenu()"
                >
                    <div
                        class="menu__toggler absolute m-2"
                        :class="{ active: showMenu }"
                    >
                        <span></span>
                    </div>
                </div>
                <router-link to="/" class="inline-block logo">
                    <img src="/images/logo.png" />
                </router-link>
            </div>
            <div
                class="menu md:flex flex-grow items-center justify-end"
                v-bind:class="{ active: showMenu }"
            >
                <div
                    class="
                        menu-items
                        text-black
                        md:text-white
                        block
                        md:flex
                        flex-col
                        md:flex-row md:ml-auto
                        font-light
                    "
                >
                    <router-link
                        class="px-3 py-2 md:py-2 flex hover:font-normal"
                        to="/"
                        :class="{
                            'font-semibold': $route.path === '/',
                        }"
                    >
                        首頁
                    </router-link>
                    <router-link
                        class="px-3 py-2 md:py-2 flex hover:font-normal group"
                        to="/about"
                        :class="{
                            'font-semibold': $route.path === '/about',
                        }"
                    >
                        關於優兔
                    </router-link>
                    <div v-on:mouseenter.stop="onToggle">
                        <router-link
                            class="
                                px-3
                                py-2
                                md:py-2
                                flex
                                hover:font-normal
                                group
                                relative
                            "
                            :class="{
                                'text-gray-900': serviceActive,
                                'font-semibold': $route.path === '/services',
                            }"
                            to="/services"
                        >
                            服務項目
                            <svg
                                class="
                                    text-gray-100
                                    h-5
                                    w-5
                                    group-hover:text-gray-500
                                "
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </router-link>
                        <div
                            class="block md:absolute"
                            :class="{ 'md:hidden': !serviceActive }"
                        >
                            <transition
                                enter-active-class="transition ease-out duration-200"
                                enter-from-class="opacity-0 translate-y-1"
                                enter-to-class="opacity-100 translate-y-0"
                                leave-active-class="transition ease-in duration-150"
                                leave-from-class="opacity-100 translate-y-0"
                                leave-to-class="opacity-0 translate-y-1"
                            >
                                <div
                                    class="z-10 transform px-2 sm:px-0 lg:ml-0"
                                >
                                    <div
                                        class="
                                            lg:shadow-lg
                                            md:ring-1
                                            ring-black ring-opacity-5
                                            overflow-hidden
                                        "
                                    >
                                        <div
                                            class="
                                                relative
                                                grid
                                                bg-white
                                                py-2
                                                text-black
                                            "
                                        >
                                            <router-link
                                                class="
                                                    px-8
                                                    whitespace-nowrap
                                                    block
                                                    py-2
                                                    hover:font-normal
                                                "
                                                :to="{
                                                    name: 'Service',
                                                    hash: '#media',
                                                }"
                                            >
                                                媒體通路
                                            </router-link>
                                            <router-link
                                                class="
                                                    px-8
                                                    whitespace-nowrap
                                                    block
                                                    py-2
                                                    hover:font-normal
                                                "
                                                :to="{
                                                    name: 'Service',
                                                    hash: '#service-process',
                                                }"
                                                >執行流程</router-link
                                            >
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <router-link
                        class="px-3 py-2 md:py-2 flex hover:font-normal"
                        to="/service-partners"
                        :class="{
                            'font-semibold':
                                $route.path === '/service-partners',
                        }"
                    >
                        服務夥伴
                    </router-link>
                    <router-link
                        class="px-3 py-2 md:py-2 flex hover:font-normal"
                        :to="{
                            name: 'Home',
                            hash: '#contact',
                        }"
                    >
                        聯絡我們
                    </router-link>
                </div>
            </div>
        </div>
        <div
            :class="{ hidden: !showMenu }"
            class="
                block
                md:hidden
                h-screen
                w-screen
                absolute
                top-0
                left-0
                bg-black
                opacity-70
                transition
                duration-600
            "
            v-on:click="onToggleMenu"
        ></div>
    </nav>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            showMenu: 'isOpenMenu',
            serviceActive: 'isOpenMenuService',
        }),
    },
    methods: {
        onToggle() {
            this.$store.commit('ON_TOGGLE_MENU_SERVICES');
        },
        onToggleMenu() {
            this.$store.commit('ON_TOGGLE_MENU');
        },
    },
};
</script>
<style lang="scss" scoped>
nav {
    z-index: 1000;
    transform: translate3d(0, 0, 0);
}
.logo {
    width: 90px;
    vertical-align: middle;
}

.menu__toggler {
    z-index: 999;
    height: 100%;
    width: 100%;
    outline: none;
    cursor: pointer;
    align-items: center;

    span,
    span::before,
    span::after {
        @apply w-8 h-4;
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background: black;
        border-radius: 20px;
        transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
        top: 6px;
    }

    span::before {
        top: -6px;
    }

    span::after {
        top: 6px;
    }

    &.active > span {
        background: transparent;
    }

    &.active > span::before,
    &.active > span::after {
        background: black;
        top: 0px;
    }

    &.active > span::before {
        transform: rotate(-225deg);
    }

    &.active > span::after {
        transform: rotate(225deg);
    }
}

@media (max-width: 767px) {
    .menu {
        position: absolute;
        left: -250px;
        top: 0;
        z-index: 998;
        color: #005c9c;
        background: white;
        min-width: 250px;
        height: 100vh;
        padding: 1rem;
        flex-direction: column;
        justify-content: center;
        transition: 500ms;
        @media only screen and (max-width: 600px) {
            width: 250px;
            left: -250px;
        }

        .menu-items {
            @apply mt-10 p-2;
        }

        &.active {
            left: 0px;
        }
    }
}
</style>
