<template>
    <div class="bg-black pt-10 lg:pt-4">
        <div class="page md:flex">
            <div class="text-white" id="cc">
                <p class="title text-blue">聯絡我們</p>
                <p class="text-white mb-2">CONTACT US</p>
                <div class="leading-8 mt-10 mr-10">
                    <p class="font-bold leading-8">優兔數位有限公司</p>
                    <a
                        class="leading-7 md:leading-8 hover:underline"
                        href="https://maps.google.com/maps?ll=25.041154,121.578971&z=16&t=m&hl=zh-TW&gl=US&mapclient=embed&cid=145897544569221075"
                    >
                        台北市信義區忠孝東路五段 510 號 12 樓之 3
                    </a>
                    <br />
                    <a
                        class="
                            leading-7
                            md:leading-8
                            hover:underline
                            underline
                            md:no-underline
                        "
                        href="tel:0227277282"
                    >
                        02-2727-7282
                    </a>
                    <br />
                    <a
                        class="
                            leading-7
                            md:leading-8
                            underline
                            md:no-underline
                            hover:underline
                        "
                        href="mailto:hello@jubbit.com.tw"
                    >
                        hello@jubbit.com.tw
                    </a>
                    <a
                        class="
                            leading-7
                            md:leading-8
                            hover:underline
                            text-red-400
                        "
                        href="https://www.facebook.com/hellojubbit"
                        target="_blank"
                    >
                        <div class="fb-icon mt-2"></div>
                    </a>
                </div>

                <div class="flex max-w-lg mt-4">
                    <div>
                        <a
                            href="https://www.facebook.com/business/partner-directory/details?solution_type=campaign_management&id=792315604928291&section=overview"
                            target="_blank"
                        >
                            <img src="/images/corp_1.png" />
                        </a>
                    </div>
                    <div><img src="/images/corp_2.png" /></div>
                    <div>
                        <a
                            href="https://pages.lazada.sg/wow/i/sg/smp/cb-landing"
                            target="_blank"
                        >
                            <img src="/images/corp_3.png" />
                        </a>
                    </div>
                    <div><img src="/images/corp_4.png" /></div>
                    <div><img src="/images/corp_5.png" /></div>
                </div>
            </div>
            <div class="pr-1 mt-6 md:mt-0 lg:pl-10 flex-1">
                <div class="w-full h-full">
                    <iframe
                        width="100%"
                        height="350px"
                        style="border: 0"
                        loading="lazy"
                        allowfullscreen
                        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJZcAuv52rQjQR02-mNQtVBgI&key=AIzaSyAUZnP2vfX3TcjzGsfq3XwBC4ccnA0kWxM"
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import animate from './../../animate';
export default {
    mounted() {
        new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    animate('#cc', 'fadeInLeft');
                }
            });
        }).observe(document.getElementById('cc'));
    },
};
</script>
