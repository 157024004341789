<template>
    <div class="page">
        <p class="title text-center">績效表現</p>
        <p class="text-blue text-center mb-2">OUR PERFORMANCE</p>
        <div
            id="performance"
            class="
                grid grid-cols-2
                gap-8
                lg:grid-cols-4 lg:divide-x lg:divide-gray-200
                py-10
            "
        >
            <div class="p-3 text-center font-bold">
                <p class="text-blue whitespace-nowrap text-3xl md:text-5xl">
                    <span class="count" data-target="10">10</span>億+
                </p>
                <br />
                <span class="text-lg">廣告花費</span>
            </div>
            <div class="p-3 text-center font-bold">
                <p class="text-blue whitespace-nowrap text-3xl md:text-5xl">
                    <span class="count" data-target="180">180</span>+
                </p>
                <br />
                <span class="text-lg">客戶數</span>
            </div>
            <div class="p-3 text-center font-bold">
                <p class="text-blue whitespace-nowrap text-3xl md:text-5xl">
                    <span class="count" data-target="2">2</span>億次+
                </p>
                <br />
                <span class="text-lg">導流數量</span>
            </div>
            <div class="p-3 text-center font-bold">
                <p class="text-blue whitespace-nowrap text-3xl md:text-5xl">
                    <span class="count" data-target="33 ">33</span>億次+
                </p>
                <br />
                <span class="text-lg">廣告曝光</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        const app = this;
        if (window.IntersectionObserver) {
            let observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        app.onCount();
                    }
                });
            });
            // console.log(document.getElementById('performance'));
            observer.observe(document.getElementById('performance'));
        }
    },
    methods: {
        onCount() {
            const counters = document.querySelectorAll('.count');
            const speed = 15;

            counters.forEach((counter) => {
                const updateCount = () => {
                    const target = parseInt(
                        counter.getAttribute('data-target')
                    );
                    const count = parseInt(counter.innerText);
                    const increment =
                        target > 10 ? Math.trunc(target / speed) : 1;
                    if (count < target) {
                        counter.innerText = count + increment;
                        setTimeout(updateCount, 80);
                    } else {
                        counter.innerText = target;
                    }
                };
                counter.innerText = 1;
                updateCount();
            });
        },
    },
};
</script>
