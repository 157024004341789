<template>
    <m-header></m-header>
    <router-view />
    <m-footer></m-footer>
</template>
<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
    components: {
        mHeader: Header,
        mFooter: Footer,
    },
};
</script>

<style lang="scss">
@import 'assets/app.scss';
</style>
