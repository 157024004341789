<template>
    <div>
        <div class="page">
            <div class="block md:flex flex-wrap justify-center text-center">
                <div
                    class="
                        md:flex-triple
                        lg:flex-1
                        p-2
                        md:p-4
                        service-c service-n-1
                    "
                >
                    <img
                        src="/images/s_01.png"
                        class="m-auto p-1 w-24 md:w-18 lg:w-24"
                    />
                    <p
                        class="
                            text-2xl
                            lg:text-3xl
                            text-blue
                            font-bold
                            leading-10
                            p-2
                        "
                    >
                        廣告投放
                    </p>
                    <hr class="my-2" />
                    <ul class="p-4">
                        <li class="leading-loose">廣告目標與策略規劃</li>
                        <li class="leading-loose animate__delay-m2s">
                            廣告投放操作
                        </li>

                        <li class="leading-loose animate__delay-m4s">
                            廣告成效優化
                        </li>

                        <li class="leading-loose animate__delay-m6s">
                            客製化報表
                        </li>
                    </ul>
                </div>
                <div
                    class="
                        md:flex-triple
                        lg:flex-1
                        p-2
                        md:p-4
                        service-c service-n-2
                    "
                >
                    <img
                        src="/images/s_02.png"
                        class="m-auto p-1 w-24 md:w-18 lg:w-24"
                    />
                    <p
                        class="
                            text-2xl
                            lg:text-3xl
                            text-blue
                            font-bold
                            leading-10
                            p-2
                        "
                    >
                        數位分析
                    </p>
                    <hr class="my-2" />
                    <ul class="p-4">
                        <li class="leading-loose">廣告數據分析</li>
                        <li class="leading-loose animate__delay-m2s">
                            消費者輪廓洞察
                        </li>
                        <li class="leading-loose animate__delay-m4s">
                            網站流量分析
                        </li>
                        <li class="leading-loose animate__delay-m6s">
                            資訊圖表化
                        </li>
                    </ul>
                </div>
                <div
                    class="
                        md:flex-triple
                        lg:flex-1
                        p-2
                        md:p-4
                        service-c service-n-3
                    "
                >
                    <img
                        src="/images/s_03.png"
                        class="m-auto p-1 w-24 md:w-18 lg:w-24"
                    />
                    <p
                        class="
                            text-2xl
                            lg:text-3xl
                            text-blue
                            font-bold
                            leading-10
                            p-2
                        "
                    >
                        媒體媒合
                    </p>
                    <hr class="my-2" />
                    <ul class="p-4">
                        <li class="leading-loose">客製化媒體策略</li>
                        <li class="leading-loose animate__delay-m2s">
                            媒體建議書
                        </li>
                        <li class="leading-loose animate__delay-m4s">
                            預期效益評估
                        </li>
                    </ul>
                </div>
                <div
                    class="
                        md:flex-triple
                        lg:flex-1
                        p-2
                        md:p-4
                        service-c service-n-4
                    "
                >
                    <img
                        src="/images/s_04.png"
                        class="m-auto p-1 w-24 md:w-18 lg:w-24"
                    />
                    <p
                        class="
                            text-2xl
                            lg:text-3xl
                            text-blue
                            font-bold
                            leading-10
                            p-2
                        "
                    >
                        顧問教學
                    </p>
                    <hr class="my-2" />
                    <ul class="p-4">
                        <li class="leading-loose">數位廣告顧問</li>
                        <li class="leading-loose animate__delay-m2s">
                            企業內部教育訓練
                        </li>
                    </ul>
                </div>
                <div
                    class="
                        md:flex-triple
                        lg:flex-1
                        p-2
                        md:p-4
                        service-c service-n-5
                    "
                >
                    <img
                        src="/images/s_05.png"
                        class="m-auto p-1 w-24 md:w-18 lg:w-24"
                    />
                    <p
                        class="
                            text-2xl
                            lg:text-3xl
                            text-blue
                            font-bold
                            leading-10
                            p-2
                        "
                    >
                        口碑行銷
                    </p>
                    <hr class="my-2" />
                    <ul class="p-4">
                        <li class="leading-loose">多元網紅媒合</li>
                        <li class="leading-loose animate__delay-m2s">
                            提高品牌聲量
                        </li>
                        <li class="leading-loose animate__delay-m4s">
                            打造產品口碑
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import animateCSSGroup from './../../animateGroup';

export default {
    mounted() {
        if (window.IntersectionObserver) {
            document.querySelectorAll('.service-c').forEach((element, i) => {
                new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            animateCSSGroup(
                                `.service-n-${i + 1} .leading-loose`,
                                'flipInX'
                            );
                        }
                    });
                }).observe(element);
            });
        }
    },
};
</script>
<style lang="scss">
.animate__delay-m2s {
    animation-delay: 0.2s;
}
.animate__delay-m4s {
    animation-delay: 0.4s;
}
.animate__delay-m6s {
    animation-delay: 0.8s;
}
.animate__delay-m8s {
    animation-delay: 0.8s;
}
</style>
