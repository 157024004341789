<template>
    <div class="page">
        <p class="title text-center">服務夥伴</p>
        <p class="text-blue text-center mb-2">OUR CLIENTS</p>
        <div class="w-full py-12">
            <splide :options="options">
                <splide-slide v-for="(img, index) in imgs" :key="`p_${index}`">
                    <img :src="`/images/partners/p_${index + 1}.png`" />
                </splide-slide>
            </splide>
        </div>
        <p class="text-center">
            <router-link
                class="text-sm text-gray-400 hover:underline"
                to="/service-partners"
                >查看更多服務夥伴</router-link
            >
        </p>
    </div>
</template>
<script>
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
    data() {
        return {
            imgs: Array.from(Array(20).keys()),
            options: {
                padding: '1rem',
                arrows: false,
                pagination: false,
                type: 'loop',
                rewind: true,
                gap: '1rem',
                perPage: 5,
                autoplay: true,
                breakpoints: {
                    640: {
                        padding: '0rem',
                        perPage: 3,
                        gap: '0rem',
                    },
                },
            },
        };
    },
    components: {
        Splide,
        SplideSlide,
    },
};
</script>
